import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
// import { RegisterModule } from './admin/register/register.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { HttpModule } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { TableModule } from 'primeng/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MessageService } from 'primeng/api';
// import { ToastModule } from 'primeng/toast';
import { AuthInterceptor } from "./auth/auth.interceptor";
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { ConfigFactory, ConfigService, rootUrl } from './services/config';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTableModule,
  // MatIcon
} from '@angular/material';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { AlertpopupComponent } from './dealer/alertpopup/alertpopup.component';
import { SelectvehiclepopupComponent } from './dealer/selectvehiclepopup/selectvehiclepopup.component';
//import { ConfirmationpopupComponent } from './dealer/confirmationpopup/confirmationpopup.component';
import { CnfrmreportpopupComponent } from './dealer/cnfrmreportpopup/cnfrmreportpopup.component';
// import { MatStepperModule } from '@angular/material/stepper';
// import { DealerComponent } from './dealer/dealer.component';
import { TrackviewpopupComponent } from './admin/trackviewpopup/trackviewpopup.component';
import { ViewleasepopupComponent } from './dealer/viewleasepopup/viewleasepopup.component';
import { PrintpopupComponent } from './dealer/printpopup/printpopup.component';
import { PwdpopupComponent } from './dealer/pwdpopup/pwdpopup.component';
import { PipesModule } from './pipe/pipe.module';
import { PopupModelComponent } from './model-popup/popup-model/popup-model.component';
import { ErrorInterceptor } from './auth/error.interceptor';
// import { SpinnerModule } from './spinner/spinner.module';
import { SelectdealerpopupComponent } from './dealer/selectdealerpopup/selectdealerpopup.component';
import { OrderListModule } from 'primeng/orderlist';
import { DeleteUserComponent } from './delete-user/delete-user.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { ConfirmationpopupComponent } from './dealer/confirmationpopup/confirmationpopup.component';
import { DealerComponent } from './dealer/dealer.component';
import { environment } from 'src/environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    // DealerComponent,
    TrackviewpopupComponent,
    ViewleasepopupComponent,
    AlertpopupComponent,
    SelectvehiclepopupComponent,
    PrintpopupComponent,
    PwdpopupComponent,
    CnfrmreportpopupComponent,
    ConfirmationpopupComponent,
    SelectdealerpopupComponent,
    PopupModelComponent,
    DeleteUserComponent,
    DealerComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatButtonModule,
    // MatAutocompleteModule,
    // SpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatRadioModule,
    MatCheckboxModule,
    // MatStepperModule,
    MatToolbarModule,
    //MatListModule,
    //FormsModule,
    MatTabsModule,
    //LoginModule,
    HttpModule,
    PipesModule,
    // RegisterModule,
    TableModule,
    //PaginatorModule,
    // ToastModule,
    //ButtonModule,
    HttpClientModule,
    MatDialogModule,
    //MatRadioModule,
    MatSelectModule,
    OrderListModule,
    GalleryModule.withConfig({
      loadingMode: 'indeterminate'
    }),
    LightboxModule,
    ProgressBarModule,
    AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
    AngularFireDatabaseModule,
    // MatIcon
  ],
  entryComponents: [TrackviewpopupComponent, PrintpopupComponent, ViewleasepopupComponent, AlertpopupComponent, SelectvehiclepopupComponent, PwdpopupComponent, CnfrmreportpopupComponent, ConfirmationpopupComponent, PopupModelComponent, SelectdealerpopupComponent, DeleteUserComponent],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ConfigService,
    { provide: 'CONFIGPATH', useValue: '/assets/config.json' },
    { provide: 'APIURL-VAR', useValue: 'rootUrl' },
    {
      provide: rootUrl, useFactory: ConfigFactory,
      deps: [ConfigService, 'CONFIGPATH', 'APIURL-VAR']
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


