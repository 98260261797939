import { Component, OnInit, Inject } from '@angular/core';
import { CustomerComponent } from '../customer/customer.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-selectvehiclepopup',
  templateUrl: './selectvehiclepopup.component.html',
  styleUrls: ['./selectvehiclepopup.component.scss']
})
export class SelectvehiclepopupComponent implements OnInit {
  displayedColumns: string[] = ['year', 'make', 'model', 'trim'];
  vehiclIn: any[];
  VehicleinfoForm: FormGroup;
  dataVehicle: any;

  chooseVehicle(event) {
    console.log('vehicle', event.value);
    console.log('vehicle1', this.vehiclIn[event.value]);
    this.dataVehicle = this.vehiclIn[event.value];
    // this.VehicleinfoForm.get("year").setValue(this.dataVehicle.year);
  }
  
  constructor(public dialogRef: MatDialogRef<CustomerComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.vehiclIn = this.data.vehiclIn;
    console.log('vehiclIn', this.vehiclIn);
  }
  onNoClick(): void {
    this.dialogRef.close(this.dataVehicle);
  }
  
  
}



export interface Object {
  year: any;
  make: any;
  model: any;
  trim: any;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {year: 2020, make: 'Make1', model: 'ModalXYZ', trim: 'Trim1'},
//   {year: 2018, make: 'Make2', model: 'ModalABC', trim: 'Trim2'},
//   {year: 2020, make: 'Make3', model: 'ModalXYE', trim: 'Trim3'},
// ];
