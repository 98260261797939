import { Component, Inject } from '@angular/core';
import * as momentTimeZone from 'moment-timezone';
import { AuthService } from './shared/auth.service';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { rootUrl } from './services/config';
declare var $:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',  
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'leaseintake';
  loginCredetial:string;
  
  // Sets initial value to true to show loading spinner on first load
  loading:boolean =  true
  constructor(public auth:AuthService,private router: Router,@Inject(rootUrl) public baseUrl?: string) {
      
if (this.baseUrl) {
  let ur=this.baseUrl.split(':');
  if(ur[0]=='https'){
  //  enableProdMode();
    console.log('url ',ur[1]);
// window.console.log=function(){};
  }

}
      

    localStorage.setItem('timeZone',momentTimeZone.tz.guess());
    this.windowCloseLogout();
    window.addEventListener('storage', (event) => {
      if(sessionStorage.getItem('userToken')){

        if (event.storageArea == localStorage) {
          let token = localStorage.getItem('isLogin');
          if(token == undefined) { // you can update this as per your key
              // DO LOGOUT FROM THIS TAB AS WELL
              // this.router.navigate(['/']); // If you are using router
              // OR
              // window.location.href = '<home page URL>';
              // window.location.href='/login';
              this.router.navigate(['/login']); 
          }
        }

      }
    })
    this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    })
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }
  
  public logOut() {
    console.log('app Logout');
    sessionStorage.removeItem('userToken');
    localStorage.removeItem('UserName');
    localStorage.removeItem('expires_in');
    this.onSetLocalStorage();
    // this.stop();
    // this.stopWatching();
    // this.setTitle('Login') ;
    // this.router.navigate(['/login']);
  }

  public onSetLocalStorage() {
    this.loginCredetial = sessionStorage.getItem('userToken')
    if (this.loginCredetial != null) {
      console.log('logedin');
    } else {
      console.log('logedout');
    }
    //console.log(sessionStorage.getItem('userToken'));
  }

  windowCloseLogout() {
    //  console.log(navigator.appVersion);
  
  // Browser Tab Open Clear local Storage
  
     if (navigator.appVersion.indexOf("Win") != -1) {
       if (window.performance) {
         console.log("window.performance works fine on this browser");
       }
       console.log('performance.navigation.type ', performance.navigation.type);
       if (performance.navigation.type == 1) {
        //  console.log("This page is Refresh");
      // localStorage.setItem('TYPE',(performance.navigation.type).toString());
       } else {
         sessionStorage.removeItem('userToken');
         this.clearLoaclstorage();
        //  console.log("This page is not reloaded");
        //  this.auth.createLocalStorage();
        //  localStorage.setItem('TYPE',(performance.navigation.type).toString());
        // this.localStorageclear();
       }
     } else if (navigator.appVersion.indexOf("Mac") != -1) {
  
     } else if (navigator.appVersion.indexOf("X11") != -1) {
  
     } else if (navigator.appVersion.indexOf("Linux") != -1) {
  
     }
  
   }
   clearLoaclstorage(){
    //  if(sessionStorage.getItem('userToken')){
      // sessionStorage.removeItem('userToken');
      localStorage.clear();

    //  }
   }
   locationReload(){
    if(sessionStorage.getItem('userToken')){
      sessionStorage.removeItem('userToken');
      // window.location.href='/login';
      this.router.navigate(['/login']); 
     }
   }

}
