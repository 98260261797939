import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { error } from 'util';
import { Http, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router, private httpClient: HttpClient, private http: Http) {
  }
  isLogedIn() {
    return sessionStorage.getItem('userToken') && localStorage.getItem('isLogin') ? sessionStorage.getItem('userToken') : null;
  }

  adminLogOut() {
    sessionStorage.removeItem('userToken');
    localStorage.clear();
    this.router.navigate(['/login']);
  }
  isLogedOut(){
    if(!localStorage.getItem('isLogin') && sessionStorage.getItem('userToken')){
      return true
    }else{
      return false;
    }

    // return sessionStorage.getItem('userToken') && localStorage.getItem('isLogin') ? sessionStorage.getItem('userToken') : null;
  }

  getItem(key:string){
    return localStorage.getItem(key)
  }

  forceLogout(){
    sessionStorage.removeItem('userToken');
    localStorage.clear();
    this.router.navigate(['/login']);
    console.log('Loading dismissed!');
  }
}
