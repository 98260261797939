import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(!value || value == null){
      return null;
    }
//console.log('value ', moment(value).format('ll'));
//console.log('Today ', moment().format('ll'));
let date = moment(value).format('ll');
if(moment().format('ll') === date){
return 'Today';
}else{
    return date;
  }
  }
}
