import { Injectable } from '@angular/core';
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class DatetimeService {

  constructor() { }
  convertUTC(date){
     let utcDate = moment.utc(date).format() // get '2019-10-11T12:32:35.567' then conver to utc
    console.log(utcDate)
    return utcDate;
  }
  convertToLocal(date){
    let cnvDate = moment(date).format('ll');
    // let cnvDate = momentTimeZone.tz(date, localStorage.getItem('timeZone')).format('YYYY-MM-DD') // convert with timezone
    console.log('cnvDate',cnvDate);
    return cnvDate;
  }
}
