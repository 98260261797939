import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RegisterComponent } from "../register/register.component";

@Component({
  selector: 'app-trackviewpopup',
  templateUrl: './trackviewpopup.component.html',
  styleUrls: ['./trackviewpopup.component.scss']
})
export class TrackviewpopupComponent implements OnInit {
  cols: any[];
  cars: any[];
  constructor(public dialogRef: MatDialogRef<RegisterComponent>, @Inject(MAT_DIALOG_DATA) public data) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
    this.cols = [
      { field: 'datetime', header: 'Date & Time' },
      { field: 'changes', header: 'Changes' },
      { field: 'changedby', header: 'Changed By' }
    ];
    this.cars = [
      { datetime: '2019-08-01T18:54:45.273', changes: 'Added Vin   HGHGIU8798kjbh89,Added Year   2018,Added Make   HONDA,Added Model   FX', changedby: 'nagaraj vanaraj'},
      { datetime: '2019-08-01T18:54:45.273', changes: 'Changed Vin HGHGIU8798kjbh89 to 34jb78dfldkfjs435', changedby: 'nagaraj vanaraj' },
      { datetime: '2019-08-02T10:37:29.91', changes: 'Changed Vin HGHGIU8798kjbh89 to 34jb78dfldkfjs435', changedby: 'nagaraj vanaraj' },
      { datetime: '2019-08-02T10:37:29.91', changes: 'Changed Vin HGHGIU8798kjbh89 to 34jb78dfldkfjs435', changedby: 'nagaraj vanaraj' },
      { datetime: '2019-08-02T10:37:29.91', changes: 'Changed Vin HGHGIU8798kjbh89 to 34jb78dfldkfjs435', changedby: 'nagaraj vanaraj' },
      { datetime: '2019-08-02T10:37:29.91', changes: 'Changed Vin HGHGIU8798kjbh89 to 34jb78dfldkfjs435', changedby: 'nagaraj vanaraj' },
      { datetime: '2019-08-02T10:37:29.91', changes: 'Changed Vin HGHGIU8798kjbh89 to 34jb78dfldkfjs435', changedby: 'nagaraj vanaraj' },
      { datetime: '2019-08-02T10:37:29.91', changes: 'Changed Vin HGHGIU8798kjbh89 to 34jb78dfldkfjs435', changedby: 'nagaraj vanaraj' },
      { datetime: '2019-08-02T10:37:29.91', changes: 'Changed Vin HGHGIU8798kjbh89 to 34jb78dfldkfjs435', changedby: 'nagaraj vanaraj' },

    ];
  }

}
