import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA,MatFormFieldModule} from '@angular/material';
import { CustomerComponent } from '../customer/customer.component';
import { UserService } from '../../shared/user.service';
import { AuthService } from '../../shared/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { MatRadioChange } from '@angular/material';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-printpopup',
  templateUrl: './printpopup.component.html',
  styleUrls: ['./printpopup.component.scss']
})
export class PrintpopupComponent implements OnInit {
  printersList: any = [];
  printchk:boolean=false;
  Dealer_id: string;
  Rolling_id: string;
  printerForm: FormGroup;
  printvalue:any = [];
  Loading:boolean=false;
  comp:boolean=false;
  txt:String;
  txt_arr:any=[];
  IMG_URL: any = environment.IMG_URL;
  constructor(private auth: AuthService, private messageService: MessageService, private userService: UserService, private _fb: FormBuilder, public dialogRef: MatDialogRef<CustomerComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    console.log(data);

  }

  ngOnInit() {
    this.Loading=true;
    this.GetPrintDetails(this.data.id);
    if(this.data.vehi_status !=0 && this.data.sig_status != 0 && this.data.cus_status !=0){
      this.txt='Please Enter Customer Information, Vehicle Information & Signature';
      this.txt_arr=['Customer Information','Vehicle Information','Acknowledge Your Lease'];
      this.comp=false;
    }
    if(this.data.vehi_status == 0 && this.data.sig_status != 0 && this.data.cus_status !=0){
      this.txt='Please Enter Customer Information & Signature';
      this.txt_arr=['Customer Information','Acknowledge Your Lease'];

      this.comp=false;
    }
    if(this.data.vehi_status != 0 && this.data.sig_status == 0 && this.data.cus_status !=0){
      this.txt='Please Enter Customer Information & Vehicle Information';
      this.txt_arr=['Customer Information','Vehicle Information'];

      this.comp=false;
    }
    if(this.data.vehi_status != 0 && this.data.sig_status != 0 && this.data.cus_status ==0){
      this.txt='Please Enter Vehicle Information & Signature';
      this.txt_arr=['Vehicle Information','Acknowledge Your Lease'];

      this.comp=false;
    }
    if(this.data.vehi_status !=0 && this.data.sig_status == 0 && this.data.cus_status ==0){
      this.txt='Please Enter Vehicle Information';
      this.txt_arr=['Vehicle Information'];

      this.comp=false;
    }
    if(this.data.vehi_status == 0 && this.data.sig_status != 0 && this.data.cus_status ==0){
      this.txt='Please Acknowledge Your Lease';
      this.txt_arr=['Acknowledge Your Lease'];

      this.comp=false;
    }
    if(this.data.vehi_status == 0 && this.data.sig_status == 0 && this.data.cus_status !=0){
      this.txt='Please Enter Customer Information';
      this.txt_arr=['Customer Information'];

      this.comp=false;
    }
    if(this.data.vehi_status == 0 && this.data.sig_status == 0 && this.data.cus_status ==0){
      this.comp=true;
    }
//     this.comp=this.data.comp;
//     if(this.comp){
//     console.log('true')
//     }
// else{
//   if(this.data.tstatus != 0 && this.data.vehi_status != 0 && this.data.cus_status != 0){    
//     this.txt='Please enter customer,vehicle and drop off details To Print';
//     console.log('1')
//   }
//   else if(this.data.vehi_status == 0 && this.data.cus_status != 0){    
//     this.txt='Please enter customer information To Print';
//     console.log('2')
//   } else if(this.data.tstatus != 0 && this.data.vehi_status != 0){    
//        this.txt='Please enter vehicle information To Print';
//        console.log('3')
//     }
//   else{ 
//      this.txt='Please enter customer,vehicle and drop off details To Print';
// }
  // else if(this.data.tstatus != 0 && this.data.vehi_status != 0){    
  //   this.txt='Please enter vehicle information To Print';
  //   console.log('3')
  // }
  // else if(this.data.tstatus == 0 && this.data.vehi_status != 1 && this.data.cus_status != 1){    
  //   this.txt='Please enter drop off details To Print';
  //   console.log('4')
  // }
//}    
    this.printerForm = this._fb.group({
      printer: ['', Validators.required]
    });
  }
  GetPrintDetails(id) {
    this.userService.GetPrinterforprint(id)
      .subscribe((data: any) => {
   this.Loading=false;
   
  this.printersList = data;
  console.log('print list',this.printersList.length);
if(data.length > 0){
this.Dealer_id = data[0].Dealer_id;
   this.Rolling_id = data[0].Rolling_id;
}
      },
        error => {
     this.Loading=false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' });
        })
  }
  ok_lease(){
  this.dialogRef.close(1);
}
  // GetPrinterList(dealerid) {
    
  //   this.userService.GetPrinter(dealerid)
  //     .subscribe((data: any) => {
  //   //  console.log('printer list',data);
  //    this.printers=data.PrinterList;
  //     //  this.dealerfilternames = dealerdet.NameList;
  //     },
  //       error => {
  //       })
  // }
  Prints() {
if(this.printvalue==''){
  this.printchk=true;
  return;
}
this.printchk=false;
    let array=[this.printvalue];
    console.log(array);
    // var printers = [];
    // this.printerForm.value.printer.forEach(element => {
    //   printers.push(element);
    // });
    //var printLabel = Object.assign({ "Dealer_id": this.Dealer_id, "Rolling_id": this.Rolling_id, "Printer_id": printers.join(',') });
   // var printLabel = Object.assign({ "Dealer_id": this.Dealer_id, "Rolling_id": this.Rolling_id, "Printer_id": this.Rolling_id });
    this.userService.printLabel(array)
      .subscribe((data: any) => {
        if (data == 1) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated label has been printed successfully!' });
        } else if (data == -1) {
          this.logout();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Label not printed!' });
        }
        this.dialogRef.close();
      },
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong!' });
        })
    this.dialogRef.close();
  }
  getprint(event: MatRadioChange){
      this.printchk=false;
 
    this.printvalue=event.value;
    console.log('print value',this.printvalue);
  }

  logout() {
    // this.userService.logout()
    //   .subscribe((data: any) => {
    //     console.log(data);
        this.auth.adminLogOut();
      // },
      //   error => {
      //   })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
