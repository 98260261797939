import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry ,tap} from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../shared/auth.service';
import { PopupModelComponent } from '../model-popup/popup-model/popup-model.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
// import { ErrorLogService } from '../shared/error-log.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  firstError = 1;
    constructor(private authService: AuthService,
       public dialog: MatDialog,
        public router: Router,
        //  private errorLogService :ErrorLogService
         ) { }

    private applyCredentials = function (request) {
        console.log('applyCredentials');
        return  request.clone({
                 setHeaders: { 
                   Authorization: 'Bearer '+ sessionStorage.getItem('userToken')
                }
            });
    };
    openDialog(){
        const dialogRef = this.dialog.open(PopupModelComponent, {
          width: '350px',
          data: { sub: 'Session Expired', title: 'Network Error!' }
        });
      
        dialogRef.afterClosed().subscribe(res => {
          console.log(res);
          // window.location.reload();
          this.authService.forceLogout();
        });
      }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
          console.log('err.status',err.status);
            // if (err.status === 401 || err.status === 404 || err.status === 405 || err.status === 500 || err.status === 0) {
              if (err.status === 500){

                if(err.error.message == "99")
                {
                  if(sessionStorage.getItem('userToken')){
                    sessionStorage.removeItem('userToken');
                    this.openDialog();
                  }
                }

                // auto logout if 401 response returned from api
                // let errorval={
                //   "Servicename":"Error ErrorInterceptor",
                //   "errmsg":err,
                //   "userid":localStorage.getItem('User_id')
                // };
                // this.errorLogService.setErrorLog(errorval);
                
                // location.reload(true);
            }
            if (err.status === 401){
                if(sessionStorage.getItem('userToken')){
                  sessionStorage.removeItem('userToken');
                  this.authService.forceLogout();
                }
          }
            console.log("error ", err);
            console.log("error message ", err.error.message );
            console.log("error statusText", err.error.statusText);
            const error = err.error.message || err.statusText;
            return throwError(err);
        }))
    }
}