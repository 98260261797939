import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-pwdpopup',
  templateUrl: './pwdpopup.component.html',
  styleUrls: ['./pwdpopup.component.scss']
})
export class PwdpopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close(1);
  }
}
