import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
import { map } from 'rxjs/operators';
import { UserService } from '../../shared/user.service'
import { $ } from 'protractor';
@Component({
  selector: 'app-viewleasepopup',
  templateUrl: './viewleasepopup.component.html',
  styleUrls: ['./viewleasepopup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewleasepopupComponent implements OnInit {
  items: GalleryItem[];
  dealerdetails: any;
  Acc_no: string;
  Address: string;
  Bank_name: string;
  Bank_pno: string;
  City: string;
  Email: string;
  Firstname: string;
  Home: string;
  Lastname: string;
  Lease_Maturity_date: string;
  Make: string;
  Mobile: string;
  Model: string;
  Paymentleft: string;
  State: string;
  Vin_number: string;
  Work: string;
  Zipcode: string;
  anotherLocation: boolean;
  approxDamageNotes: string;
  color: string;
  date: string;
  key: any;
  lease_enddate: string;
  lease_startdate: string;
  lease_time: string;
  location: string;
  mileage: string;
  pickUp: string;
  pickUpDate: string;
  pickUpNotes: string;
  remotes: any;
  signature: string;
  trim: string;
  year: string;
  imgcount: Number;
  images: any[];


  constructor(private userService: UserService, public gallery: Gallery, public dialogRef: MatDialogRef<DashboardComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    console.log(this.data);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

    // Load items into the lightbox
    this.GetVehiDetails();
    // this.basicLightboxExample();
  }

  basicLightboxExample() {
    this.gallery.ref().load(this.items);
  }
  GetVehiDetails() {
    console.log(this.data);
    this.userService.GetVehiDetails(this.data.id)
      .subscribe((data: any) => {
        // console.log(data);
        this.dealerdetails = data;
        this.Firstname = data.Firstname;
        this.Lastname = data.Lastname;
        this.Email = data.Email;
        this.Mobile = data.Mobile;
        this.Address = data.Address;
        this.signature = data.signature;
        this.Acc_no = data.Acc_no;
        this.Address = data.Address;
        this.Bank_name = data.Bank_name;
        this.Bank_pno = data.Bank_pno;
        this.City = data.City != '' ? data.City : null;
        this.Vin_number = data.Vin_number != '' ? data.Vin_number : null;
        this.color = data.color != '' ? data.color : null;
        this.Make = data.Make != '' ? data.Make : null;
        this.Model = data.Model != '' ? data.Model : null;
        this.year = data.year != '' ? data.year : null;
        this.mileage = data.mileage != '' ? data.mileage : null;
        this.trim = data.trim != '' ? data.trim : null;
        this.signature = data.signature != '' ? data.signature : null;
        this.key=data.key;
        this.remotes=data.remotes;
        // const dataimages = [ 
        //   {
        //     srcUrl: '../assets/images/carimage/car-backbumper-6.jpg',
        //     previewUrl: '../assets/images/carimage/car-backbumper-6.jpg'
        //   },
        //   {
        //     srcUrl: '../assets/images/carimage/car-driverseat12.jpg',
        //     previewUrl: '../assets/images/carimage/car-driverseat12.jpg'
        //   },
        //   {
        //     srcUrl: '../assets/images/carimage/car-extra14.jpg',
        //     previewUrl: '../assets/images/carimage/car-extra14.jpg'
        //   },
        //   {  
        //     srcUrl: '../assets/images/carimage/car-front1.jpeg',
        //     previewUrl: '../assets/images/carimage/car-front1.jpeg'
        //   },
        //   {
        //     srcUrl: '../assets/images/carimage/car-front-rim4.jpg',
        //     previewUrl: '../assets/images/carimage/car-front-rim4.jpg'
        //   },
        //   {
        //     srcUrl: '../assets/images/carimage/car-hood2.jpg',
        //     previewUrl: '../assets/images/carimage/car-hood2.jpg'
        //   },
        //   {
        //     srcUrl: '../assets/images/carimage/car-meter13.jpg',
        //     previewUrl: '../assets/images/carimage/car-meter13.jpg'
        //   },
        //   {
        //     srcUrl: '../assets/images/carimage/car-paenl9.jpg',
        //     previewUrl: '../assets/images/carimage/car-paenl9.jpg'
        //   }
        // ];
        const dataimages = data.Images;
        let icount = 0;
        let imageSet = [];
        dataimages.forEach(element => {
          // console.log(element.url);
          if (element.url !== "" && element.url != null) {
            var img = {
              srcUrl: element.url,
              previewUrl: element.url
            };
            imageSet.push(img);
            icount++;
            // console.log(element.url);
          }
        });
        this.imgcount = icount;
        if (icount > 0) {
          this.items = imageSet.map(item =>
            new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
          );
          this.gallery.ref().load(this.items);
        }

      },
        error => {
          // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Sent!' });
        })
  }

}

