import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {

  transform(str: string): string {
    if(!str || str == null){
      return null;
    }
    return str.trim();
  }

}
