import { Component, OnInit,Inject } from '@angular/core';
import {MatDialog, MatDialogRef ,MAT_DIALOG_DATA} from "@angular/material";
import {HeaderComponent} from "../header/header.component" 
import {OrderListModule} from 'primeng/orderlist';

@Component({
  selector: 'app-selectdealerpopup',
  templateUrl: './selectdealerpopup.component.html',
  styleUrls: ['./selectdealerpopup.component.scss'],

})

export class SelectdealerpopupComponent implements OnInit {
 cars:any[];
dealer_id:any;
  constructor(public dialogRef: MatDialogRef<HeaderComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.cars=this.data.cars;
    console.log(this.cars);
    this.dealer_id=localStorage.getItem('Dealer_id');
  }
  onClose(val): void {
    console.log(val);
    console.log(this.dialogRef.disableClose);
    if(val){
    this.dialogRef.close(val); // does not close the dialog
    }
    }
  

}
