import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReportComponent } from '../report/report.component';

@Component({
  selector: 'app-cnfrmreportpopup',
  templateUrl: './cnfrmreportpopup.component.html',
  styleUrls: ['./cnfrmreportpopup.component.scss']
})
export class CnfrmreportpopupComponent implements OnInit {

 count:Number=0;
 cont:any;
  constructor(public dialogRef: MatDialogRef<ReportComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.count=this.data.id;
    this.cont=this.data.cont;
  }
  onNoClick(type:Number): void {
    this.dialogRef.close(type);
  }

}
