import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupbyPipe } from './person-name.pipe';
import { TimezonePipe } from './timezone.pipe';
import { TrimPipe } from './trim.pipe';
import { SafePipe } from './safe.pipe';


@NgModule({
  declarations: [GroupbyPipe, TimezonePipe, TrimPipe, SafePipe],
  exports : [GroupbyPipe, TimezonePipe, TrimPipe, SafePipe]
})
export class PipesModule { }
