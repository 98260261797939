import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { DatetimeService } from '../shared/datetime.service';

@Pipe({
  name: 'groupBy',
  pure: false
})
export class GroupbyPipe implements PipeTransform {
  constructor(
    private datetimeService: DatetimeService
    ){
      
    }
  transform(value: Array<any>, field: string): Array<any> {
    console.log('value',value);
    if(!value || value == null){
      return null;
    }
    console.log('value',value);
    // console.log('field',field);
    // console.log('timeZone',localStorage.getItem('timeZone'));
    var that = this;
    let gData =  _.groupBy(value, function(b) {
      return that.datetimeService.convertToLocal(b.date)
      });
      console.log('gData',gData);
      console.log('fl',Object.keys(gData).map(key => ({ key, value: gData[key] })));
      return Object.keys(gData).map(key => ({ key, value: gData[key] }));

    // const groupedObj = value.reduce((prev, cur)=> {
    //   if(!prev[cur[field]]) {
    //     prev[cur[field]] = [cur];
    //   } else {
    //     prev[cur[field]].push(cur);
    //   }
    //   return prev;
    // }, {});
    // console.log(Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] })));
    // return Object.keys(groupedObj).map(key => ({ key, value: groupedObj[key] }));
  }
}
