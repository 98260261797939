import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [

  { path:'',redirectTo:'login',pathMatch:'full'},
  { path:'login',loadChildren:() => import('./login/login.module').then(m => m.LoginModule)},
  { path:'reset/:login',loadChildren:() => import('./login/login.module').then(m => m.LoginModule)}, // welcom back login/after reset password
  { path:'admin/register',loadChildren:() => import('./admin/register/register.module').then(m => m.RegisterModule),canActivate:[AuthGuard]},
  { path: 'dealer/dashboard', loadChildren:() => import('./dealer/dashboard/dashboard.module').then(m => m.DashboardModule),canActivate:[AuthGuard]},
  { path: 'dealer/profile', loadChildren:() => import('./dealer/profile/profile.module').then(m => m.ProfileModule),canActivate:[AuthGuard]},
  { path: 'dealer/change-password', loadChildren:() => import('./dealer/change-password/change-password.module').then(m => m.ChangePasswordModule),canActivate:[AuthGuard]},
  { path: 'dealer/customer', loadChildren:() => import('./dealer/customer/customer.module').then(m => m.CustomerModule),canActivate:[AuthGuard]},
  { path: 'dealer/report', loadChildren:() => import('./dealer/report/report.module').then(m => m.ReportModule),canActivate:[AuthGuard]},
  // { path: 'dealer/search/:skey', loadChildren:() => import('./dealer/search/search.module').then(m => m.SearchModule),canActivate:[AuthGuard]}, 
  { path: 'dealer/search/:skey', loadChildren:() => import('./dealer/dashboard/dashboard.module').then(m => m.DashboardModule),canActivate:[AuthGuard]}, 
  { path: 'dealer/help', loadChildren: () => import('./dealer/help/help.module').then(m => m.HelpModule),canActivate:[AuthGuard]},
  { path: 'forgot-password', loadChildren: () => import('./forgotpass/forgotpass.module').then(m => m.ForgotpassModule) },
  { path: 'usersignature/:id/:activeCode', loadChildren: () => import('./usersignature/usersignature.module').then(m => m.UsersignatureModule) },
  { path: 'reset-password/:key/:activeResetCode', loadChildren: () => import('./resetpassword/resetpassword.module').then(m => m.ResetpasswordModule) },
  { path: ':tapkey', loadChildren: () => import('./logintab/logintab.module').then(m => m.LogintabModule) },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];
//
@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
