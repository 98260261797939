import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { UserinfoComponent } from '../admin/register/userinfo/userinfo.component';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit {
  status :number;
  constructor( public dialogRef: MatDialogRef<UserinfoComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.status = data.id;
   }

  ngOnInit() {
  }
  onNoClick(type): void {
    if(type==1){
      this.dialogRef.close(1);
    }else{
      this.dialogRef.close(0);
    }
    
  }
}
