import { Injectable, Inject } from '@angular/core';
import { Http, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, retry, catchError, shareReplay} from 'rxjs/operators';
import { BehaviorSubject, Observable, EMPTY} from 'rxjs';
import { Title } from '@angular/platform-browser';
import { rootUrl } from '../services/config';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user_logo = new BehaviorSubject(localStorage.getItem("user_log"));
  user_Logo_Data = this.user_logo.asObservable();

  private user_name = new BehaviorSubject(localStorage.getItem("User_name"));
  user_name_Data = this.user_name.asObservable();

  private dealer_logo = new BehaviorSubject(localStorage.getItem("dealer_logo"));
  dealer_logo_Data = this.dealer_logo.asObservable();

  private transid = new BehaviorSubject(null);
  transdata = this.transid.asObservable();
  
  private User_sct = new BehaviorSubject(null);
  Userscrt = this.User_sct.asObservable();

  private notify= new BehaviorSubject(null);
  notify_data = this.notify.asObservable();
  constructor(private titleService: Title,private http: Http, private httpClient: HttpClient,@Inject(rootUrl) public baseUrl?: string) { }

  changeUser_Logo(logo) {
    this.user_logo.next(logo);
  }
  changeUser_Name(name) {
    this.user_name.next(name);
  }
  changeDealer_Logo(logo) {    
    if (logo && logo.indexOf("upload/w_115,h_115,c_thumb,g_face,r_max/") > -1) {
      let url = logo.replace("upload/w_115,h_115,c_thumb,g_face,r_max/", "upload/");
      this.dealer_logo.next(url);
    }else{
      this.dealer_logo.next(logo);
    }
  }
  trans(logo) {
    this.transid.next(logo);
  }
  noti(data) {
    console.log('User Service notify', data)
    this.notify.next(data);
  }
  User_Scrt(scrt) {
    this.User_sct.next(scrt);
  }
  userAuthentication(userName, password) {
    let combainClienId = userName;
    var data = "username=" + combainClienId + "&password=" + password + "&grant_type=password";
    // console.log(data);
    var headerOptions = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    var requestOptions = new RequestOptions({ method: RequestMethod.Post, headers: headerOptions });
    return this.http.post(this.baseUrl + '/token', data, requestOptions).pipe(map(x => x.json()));
  }

  AddDealer(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // header.set('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
    // return this.httpClient.post(
    //   this.baseUrl + "/Api/Admin/AddDealer",
    //   body,
    //   { headers: headers }
    // );
    // return this.http.post(this.baseUrl + "/Api/Admin/AddDealer", body, { headers: header }).pipe(map(x => x.json()));
    return this.httpClient.post(this.baseUrl + "/Api/Admin/AddDealer", body, { headers: header });
  }
  AddCutomer(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/User/AddCust", body, { headers: header });
  }
  AddVehicleInfo(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/User/AddVehi", body, { headers: header });
  }
  AddVehiconfrm(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/User/AddVehiconfrm", body, { headers: header });
  }
  AddVehiImg(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/User/AddVehiImg", body, { headers: header });
  }
  sendsignature(data){   
    let data_value = {"transaction_id":data}
  let body = JSON.stringify(data_value);  
   var header = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(this.baseUrl + "/Api/User/getSignature",body,  { headers: header });
  }

  AddKeys_remotes(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/User/AddKeys_remotes", body, { headers: header });
  }

  logout() {
    // var header = new Headers({ 'Content-Type': 'application/json' });
    // header.set('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
    // return this.httpClient.get(this.baseUrl + '/Api/Admin/logout');
    return '1';
  }
  AddUser(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    header.set('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
    // return this.httpClient.post(
    //   this.baseUrl + "/Api/Admin/AddDealer",
    //   body,
    //   { headers: headers }
    // );
    return this.httpClient.post(this.baseUrl + "/Api/Admin/AddUser1", body, { headers: header });
  }

  UpdateUser(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // header.set('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
    // return this.httpClient.post(
    //   this.baseUrl + "/Api/Admin/AddDealer",
    //   body,
    //   { headers: headers }
    // );
    return this.httpClient.post(this.baseUrl + "/Api/Admin/UpdateUser1", body, { headers: header });
  }
  upload(data) {
    // var body = JSON.stringify(data);
    // console.log('body');
    // console.log(body);
    // var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // header.set('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
    // return this.httpClient.post(
    //   this.baseUrl + "/Api/Admin/AddDealer",
    //   body,
    //   { headers: headers }
    // );
    // return this.httpClient.post(this.baseUrl + "/Api/Admin/UpdateUser", body, { headers: header });
    // var reqHeader = new Headers({ 'No-Auth': 'True' });
    var header = new HttpHeaders({ 'No-Auth': 'True' });
    //      formdata.append('upload_preset', 'ml_default');
    //      formdata.append('api_key', '692497694432176');
    // formdata.append('api_secret', 'z3TG6U8bOndsU7vzpjwp9g8e3d4');

    return this.httpClient.post("https://api.cloudinary.com/v1_1/dlrflowcloudinary/image/upload?upload_preset=ml_default&api_key=692497694432176&api_secret=z3TG6U8bOndsU7vzpjwp9g8e3d4", data, { headers: header });
  }

  UpdateDealer(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // header.set('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
    // return this.httpClient.post(
    //   this.baseUrl + "/Api/Admin/AddDealer",
    //   body,
    //   { headers: headers }
    // );
    return this.httpClient.post(this.baseUrl + "/Api/Admin/UpdateDealer", body, { headers: header });
  }

  forgotPassword(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // header.set('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
    return this.httpClient.post(this.baseUrl + "/Api/Common/forgetPassword", body, { headers: header });
  }

  resetPassword(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // header.set('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
    return this.httpClient.post(this.baseUrl + "/Api/Common/resetPassword", body, { headers: header });
  }
getcontactless(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // header.set('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
    return this.httpClient.post(this.baseUrl + "/Api/User/decodeSignaturePage", body, { headers: header });
  }
  sendcontalesssing(data) {
    var body = JSON.stringify(data);
    console.log('body');  
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    // header.set('Authorization', 'Bearer ' + sessionStorage.getItem('userToken'));
    return this.httpClient.post(this.baseUrl + "/Api/User/getContactLessSign", body, { headers: header });
  }
  GetUserClaims() {
    return this.httpClient.get(this.baseUrl + '/Api/Common/GetUserClaims');
  }
  checkExists(name, type, delaer_id) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/Namecheck/' + name + '/' + type + '/' + delaer_id);
  }
  // checkExistsr(name, type,delaer_id) {
  //   return this.httpClient.get(this.baseUrl + '/Api/Admin/Namecheck/' + name + '/' + type+'/'+delaer_id);
  // }
  GetDealer() {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetDealer');
  }
  GetRoofUser() {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetRoofUser');
  }
  GetUser() {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetUser');
  }

  getAllUsersList() {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetAllUsersList');
  }

  getAllUsersOrderList() {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetAllOrderList');
  }

  GetLocation() {
    return this.httpClient.get(this.baseUrl + '/Api/User/GetLocation/'+ localStorage.getItem('Dealer_id'));
  }
  GetPickup() {
    return this.httpClient.get(this.baseUrl + '/Api/User/GetPickedby/'+ localStorage.getItem('Dealer_id'));
  }
  GetDealerNameList() {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetDealerNameList');
  }
  GetRoleList(dealerid) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetRoleList/' + dealerid);
  }
  GetRoleList1(dealerid) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetRoleList1/' + dealerid);
  }
  Test_printer(QCText,printerid,google_or_node) {
    return this.httpClient.get(this.baseUrl + "/Api/Print/printers_test/"+QCText+'/'+printerid+'/'+google_or_node);
  }
  updatePrinter(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/Admin/UpdatePrinter", body, { headers: header });
  }
  addPrinter(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/Admin/AddPrinter", body, { headers: header });
  }
  addvAuto(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/Admin/updateVauto", body, { headers: header });
  }
  GetvAuto(dealerid) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetVautoDetails/' + dealerid);
  }
  addPrinter_id(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/Print/PrinterDetails", body, { headers: header });
  }
  GetPrinter(dealerid) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetPrinter/' + dealerid);
  }
  editprint(dealerid,Printer_id) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetPrinter_dealerdetails/' + dealerid+ '/' + Printer_id);
  }
  GetPrinterforprint(tranid) {
    return this.httpClient.get(this.baseUrl + '/Api/User/GetPrinters/' + tranid+'/'+ localStorage.getItem('Dealer_id'));
  }
  GetPrintercheck(printerid) {
    return this.httpClient.get(this.baseUrl + '/Api/Print/PrinterCheck/' + printerid);
  }
  GetPrinter_staus(printerid) {
    return this.httpClient.get(this.baseUrl + '/Api/Print/Printerstatecheck/' + printerid);
  }
  DeletePrinter(printerid,dealerid,pstatus) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/DeletePrinter/' + printerid+'/'+ dealerid + '/' + pstatus);
  }
  print(data){   
    console.log('data',data);
    let body = JSON.stringify(data);  
   var header = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(this.baseUrl + '/Api/Print/prints', body, { headers: header });  
  }
  changestatusRole(status, dealerid, Roleid) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/DeleteRole/' + Roleid + '/' + dealerid + '/' + status);
  }
  editDealerDetails(id) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetDealerDetails/' + id);
  }
  GetUserDetails(id) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetUserDetails/' + id);
  }
  changestatusDealer(id, status) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/DeleteDealer/' + id + '/' + status);
  }
  docSignMail(id) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/Dealermailsend/' + id );
  }
  changestatusOdo(id, status) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/DeleteOdo/' + id + '/' + status);
  }
  GetVehiDetails(id) {
    return this.httpClient.get(this.baseUrl + '/Api/User/GetVehiDetails/' + id);
  }
  GetPrinters(id) {
    return this.httpClient.get(this.baseUrl + '/Api/User/GetPrinters/' + id);
  }
  changestatusUser(id, status) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/DeleteUser/' + id + '/' + status);
  }
  GetDashboardCount(getstartDate, getendDate, getstartMonth, getendMonth,st_month_count,et_month_count,ytd_from,ytd_end) {
    let data = {
      startDate :getstartDate,
      endDate : getendDate,
      startMonth :getstartMonth,
      endMonth : getendMonth,
      MTDstartDate:st_month_count,
      MTDendDate:et_month_count,
      YTDstartDate:ytd_from,
      YTDendDate:ytd_end,
      Dealer_id: localStorage.getItem('Dealer_id')
    }
    console.log('data',data);
    let body = JSON.stringify(data);  
   var header = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(this.baseUrl + '/Api/User/LandingPage', body, { headers: header });  
  }

  getDashboardData(data) {
    console.log('data',data);
    let body = JSON.stringify(data);  
   var header = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(this.baseUrl + '/Api/User/DashboardPageNew', body, { headers: header });  
  }
  
  getDashboardCount(data) {
    
    let body = JSON.stringify(data);  
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + '/Api/User/DashboardPageCount', body, { headers: header });  
   
  }
  notPickUp(val){ //1-pickup,2-notpickup,2-dropoff,4-purchased
     return this.httpClient.get(this.baseUrl + '/Api/User/notPickedUp/'+ val + '/' + localStorage.getItem('Dealer_id'));
    //return this.httpClient.get(this.baseUrl + '/Api/User/notPickedUp');
  }
  getNotificationCount(){
    return this.httpClient.get(this.baseUrl + '/Api/Common/getNotificationCount/'+ localStorage.getItem('Dealer_id')+'/'+  localStorage.getItem('User_id'));
  }
  getLandingPageDetail(val, getstartDate, getendDate) {
    let data = {
      duration : val,
      startDate :getstartDate,
      endDate : getendDate,
      Dealer_id: localStorage.getItem('Dealer_id')
    }
    console.log('data',data);
    let body = JSON.stringify(data);  
   var header = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(this.baseUrl + '/Api/User/LandingPageDeatil', body, { headers: header });
  }
  GetVinDetail(vin_no) {
    let data = {
      vin : vin_no
    }
    let body = JSON.stringify(data);  
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + '/Api/User/GetVinDetail', body, { headers: header });
  }
  GetFilterTitle() {
    return this.httpClient.get(this.baseUrl + '/Api/User/Filtertitle');
  }
  changeProfile(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/User/changeprofile_pic", body, { headers: header });
  }
  GetReport(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/User/ReportOverAll", body, { headers: header });
  }

  UpdateReport(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/User/UpdateReportOverAll", body, { headers: header });
  }

  Prints(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/Print/prints", body, { headers: header });
  }
  printLabel(data){   
    console.log('data',data);
    let body = JSON.stringify(data);  
   var header = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(this.baseUrl + '/Api/Print/prints', body, { headers: header });  
  }
  addRole(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/Admin/AddRole", body, { headers: header });
  }
  updateRole(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/Admin/EditRoleList", body, { headers: header });
  }
  GetRole(roleid, dealerid) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/GetRole/' + roleid + '/' + dealerid);
  }
  GetRoleListnew( dealerid) {
    return this.httpClient.get(this.baseUrl + '/Api/Admin/rolelist/'  + dealerid);
  }

 
  checkEmail(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/User/EmailValidation", body, { headers: header });
  }

  checkEmailCommon(data) {
    var body = JSON.stringify(data);
    console.log('body');
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + "/Api/Common/EmailValidation", body, { headers: header });
  }

  SearchLease(key) {
     return this.httpClient.get(this.baseUrl + '/Api/User/SearchLease/' + key+'/'+ localStorage.getItem('Dealer_id'));
    //return this.httpClient.get(this.baseUrl + '/Api/User/SearchLease/' + key);
  }
  getYearList() {
    return this.httpClient.get(this.baseUrl + '/Api/User/GetYear');
  }
  getFiterData(data,type) {
    if(type==2 || type == 100){
      let body = JSON.stringify(data);  
      var header = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.post(this.baseUrl + '/Api/User/TransactionSearchDate', body, { headers: header });
    }else{
    let body = JSON.stringify(data);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + '/Api/User/TransactionSearch', body, { headers: header });
    }
  }
  DeleteLease(data){   
    let body = JSON.stringify(data);  
   var header = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(this.baseUrl + '/Api/User/DeleteLease', body, { headers: header });
  }
  updatePickUpStatus(data){   
    let body = JSON.stringify(data);  
   var header = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(this.baseUrl + '/Api/User/updatePickUp', body, { headers: header });
  }
  getFilterlist(titleid) {
    return this.httpClient.get(this.baseUrl + '/Api/User/Filterlist/' + titleid+'/' +localStorage.getItem('Dealer_id'));
  }
  GetvAutoText() {
    return this.httpClient.get(this.baseUrl + '/Api/common/vautotext');
  }
  GetOdoStateText() {
    return this.httpClient.get(this.baseUrl + '/Api/common/Get_odom_states');
  }

  // getsitefilter(data, titleid) {
  //   return this.httpClient.get(this.baseUrl + '/Api/User/SiteFilter/' + data + '/' + titleid+'/' +localStorage.getItem('Dealer_id'));
  // }

  getsitefilter(data){   
    let body = JSON.stringify(data);  
   var header = new HttpHeaders({ 'Content-Type': 'application/json' });
   return this.httpClient.post(this.baseUrl + '/Api/User/SiteFilter', body, { headers: header });
  }

  updatePickUp(data) {
    let body = JSON.stringify(data);
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + '/Api/User/updatePickUp', body, { headers: header });
  }
  getProfileDetails() {
    return this.httpClient.get(this.baseUrl + '/Api/User/getUserProfile/'+ localStorage.getItem('Dealer_id'));
  }
  saveUserInfo(data){  
    console.log(data)
    let body = JSON.stringify(data);  
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl+'/Api/User/updateUserProfile', body, { headers: header });
  }
  
  changePwd(pwd){
    console.log(pwd);
    var body = "password=" + pwd ;
    console.log(body);
    var header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.httpClient.post(this.baseUrl+'/Api/User/changepassword', body, { headers: header });
  }
  getNotification(data) {
    let body = JSON.stringify(data);  
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl+'/Api/Common/getNotification', body, { headers: header });
    // return this.httpClient.get(this.baseUrl + '/Api/Common/getNotification/'+ localStorage.getItem('Dealer_id')+'/'+  localStorage.getItem('User_id')+'/'+page);
  }
  
  setTitle(newTitle: string) {
    console.log(newTitle);
      this.titleService.setTitle('Leaseintake - ' + newTitle);
  }

  getDateRangeList(data) {
    let body = JSON.stringify(data);  
    var header = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.post(this.baseUrl + '/Api/User/GetModelsearchbind', body, { headers: header });
  }
  get_Select_Image(dealerId){
    return this.httpClient.get(this.baseUrl + '/Api/User/GetImages/' + dealerId);
  }
  Email_Fax(transid, type, email) {
    let data = {
      transactionid : transid,
      type : type,
      mailto :  email
    }
    let body = JSON.stringify(data);
    var header = new HttpHeaders({ 'Content-Type' : 'application/json' });
    return this.httpClient.post(this.baseUrl + '/Api/User/email_fax', body, { headers : header});
  }

  send_email(transid,email)
  {
    let data = {
      transaction_id : transid,
      email_id:email
    }
    let body = JSON.stringify(data);
    var header = new HttpHeaders({ 'Content-Type' : 'application/json' });
    return this.httpClient.post(this.baseUrl + '/Api/User/sendLeaseFileEmail', body, { headers : header});
  }
  printOdo(data) {
    console.log('data',data);
    // return this.httpClient.get(this.baseUrl + '/Api/User/odom/' + data);
    return this.httpClient.get(this.baseUrl + '/Api/User/odomPrint/' + data);
  }
  getMakeList() {
    return this.httpClient.get(this.baseUrl + '/Api/User/GetMakeList/');
  }
  getDealer(){
    let user_id=localStorage.getItem('User_id');
    return this.httpClient.get(this.baseUrl + '/Api/User/RoofDealers/'+ user_id );
  }
  odom_mail_list(){
    return this.httpClient.get(this.baseUrl + '/Api/User/odom_mail_list/'+ localStorage.getItem('Dealer_id'));
  }
    // : Observable<string>
    odom_fax_list() { 
      return this.httpClient.get(this.baseUrl + '/Api/User/odom_fax_list/'+ localStorage.getItem('Dealer_id')).pipe(retry(5),
      catchError(() =>{
        console.log('odom_fax_list', EMPTY)
      return EMPTY;
      }),
      shareReplay()
      );
    }

    getBankSettingData(){
      return this.httpClient.get(this.baseUrl + '/Api/User/Bank_Settings/'+ localStorage.getItem('Dealer_id') +'/'+  localStorage.getItem('User_id'));
    }
    getresend_vAuto(transaction_id)
    {
      return this.httpClient.get(this.baseUrl + '/Api/User/VautoResend/'+ transaction_id);
    }
    resetPdp(data) {
      var body = JSON.stringify(data);
      console.log('body',body);
      var header = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(this.baseUrl + "/Api/User/resetPdp", body, { headers: header });
    }
    
    resetGround(data) {
      var body = JSON.stringify(data);
      console.log('body',body);
      var header = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(this.baseUrl + "/Api/User/resetGrounding", body, { headers: header });
    }

    getOdoExistEmailList(){
      return this.httpClient.get(this.baseUrl + '/Api/User/getOdoExistEmailList/'+ localStorage.getItem('Dealer_id') +'/'+  localStorage.getItem('User_id'));
    }

    resetOdoEmail(data) {
      var body = JSON.stringify(data);
      console.log('body',body);
      var header = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(this.baseUrl + "/Api/User/resetOdoEmail", body, { headers: header });
    }

    getOdoLink(transactionid)
    {
      return this.httpClient.get(this.baseUrl + '/Api/User/odomPrint/'+transactionid); 
    }

    updateDealerContact(data: any) {
      var body = JSON.stringify(data);
      console.log('body',body);
      var header = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.httpClient.post(this.baseUrl + "/Api/Admin/updateOdoMail", body, { headers: header });
    }
    
    getBankPageLink(transactionid)
    {
      return this.httpClient.get(this.baseUrl + '/Api/User/PrintVehiDetails/'+transactionid);
    }
    
}
