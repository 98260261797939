// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {  
  production: false,
  // rootUrl: "http://localhost:56333",
 // rootUrl:"http://leaseintakeapi.rcktechiees.com",
// rootUrl : "https://191.leaseintake.com",
  IMG_URL: 'https://d2a7u30t6wbkim.cloudfront.net',  
    rootUrl:"https://api.leaseintake.com",
  CLOUDINARY_UPLOAD_PRESET : 'rc9zknwv', 
  CLOUDINARY_API_KEY : '868342619359177', 
  CLOUDINARY_API_SECRET : 'KskxV9qa3VdjcE0j0NMhaGGYJxo',  
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyC0UihR8KhIjQyNsc3tYJsW_pfrdZO40sA",
    authDomain: "leaseintake-f9910.firebaseapp.com",
    databaseURL: "https://leaseintake-f9910.firebaseio.com",
    projectId: "leaseintake-f9910",
    storageBucket: "leaseintake-f9910.appspot.com",
    messagingSenderId: "241748500949",
    appId: "1:241748500949:web:9ee825396ac8bfdc029d5c",
    measurementId: "G-406CR33MBM"
  }
};

/*\
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

